body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

svg {
	padding: 4px;
}

.clickable {
	cursor: pointer;
}

.reversable:hover {
	color: white;
	background-color: rgb(33, 37, 41);
}

.container {
	text-align: center;
	margin: auto;
}

.products-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, max-content));
	grid-gap: 1em;
	justify-content: center;
	padding: initial;
	min-height: 75vh;
}

.product {
	max-width: 24vw;
	min-width: 20rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border: 5px solid rgb(33, 37, 41);
	box-shadow: 0.3em 0.6em 1em 0.2em gray;
	border-radius: 0.5em;
	margin: 1em 1em;
	padding: 1em;
}

.product-image {
	max-width: 20rem;
	height: 30vh;
	border: 2px solid gray;
	border-radius: 0.5em;
	box-shadow: 0.3em 0.6em 1em 0.2em gray;
	margin-bottom: 1em;
}

.product-footer {
	display: flex;
	justify-content: space-around;
	align-items: center;
	align-self: stretch;
	background-color: lavender;
}

.product-counter {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	max-width: 100px;
	margin: 0;
}

.input-counter {
	width: 80%;
	text-align: center;
	margin: 0.5em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#nav-bar {
	cursor: pointer;
}

.main-header, .main-footer {
	background-color: rgb(33, 37, 41);
	color: white;
	align-items: center;
	padding: 0.5em;
}

.right {
	text-align: right;
}
